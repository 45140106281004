export async function createUser(data) {
    const response = await fetch(`https://adminapplication19.iapwe.org/api/save/data`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      })
    return await response.json();
}


export async function saveImage(data) {
  const response = await fetch (`https://adminapplication19.iapwe.org/api/save-image`,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  })
  return await response.json();
}

export async function VisitorCount() {
  const response = await fetch (`https://adminapplication19.iapwe.org/api/visitor/count`,{
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify()
  })
  return await response.json();
}